import './App.css';
import { Container, Row, Col } from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <header className="App-header">

      <ul>
        <li><h1><a href="/#"><span class="text-gradient-footer">🚀 Getsy Apps</span></a></h1></li>
      </ul>

      
      <div class="hero-image">
        <div class="hero-text">
          <h2 class="hero-h1">Discover Etsy Apps that help <div class="grow">grow your business.</div></h2>
        </div>

      </div>
        
        <Container>
        <Row>
            
            <Col>
              <div class="card" >
                <h1 class="center">Marketsy</h1>
                <p class="p-card">Download your etsy shop customer emails, names and order data with one click.</p>
                <a class="center" href="https://marketsy.io"><button class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
            
            <Col>
            <div class="card" >
              <h1 class="center">EverBee</h1>
              <p>Find the best selling products on Etsy in seconds. Grow your sales today.</p>
              <a class="center" href="https://everbee.io/"><button type="button" class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
            <Col>
            <div class="card" >
              <h1 class="center">KarenCheck</h1>
              <p>See the review history of your buyers. Know if they're a "never satisfied" person.</p>
              <a class="center" href="https://karencheck.com/"><button type="button" class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
        <Row>
            
            <Col>
              <div class="card" >
                <h1 class="center">Marmalead</h1>
                <p class="p-card">Etsy SEO tool to find keywords that help you make more money.</p>
                <a class="center" href="https://marmalead.com/"><button class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
            
            <Col>
            <div class="card" >
              <h1 class="center">Erank</h1>
              <p>The largest selection of tools to improve your SEO and help grow your Etsy business.</p>
              <a class="center" href="https://erank.com/"><button type="button" class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
            <Col>
            <div class="card" >
              <h1 class="center">Crafybase</h1>
              <p>Track your materials and products, calculates COGS, log your expenses and keep your pricing on track.</p>
              <a class="center" href="https://craftybase.com/"><button type="button" class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
        <Row>
            
            <Col>
              <div class="card" >
                <h1 class="center">Etsy Marketing Tool</h1>
                <p class="p-card">Easily automate your items posting on various channels, create videos for your Etsy shop and more.</p>
                <a class="center" href="https://etsymarketingtool.com/"><button class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
            
            <Col>
            <div class="card" >
              <h1 class="center">Backtsy</h1>
              <p>Back up your etsy shop to restore your shop to any version.</p>
              <a class="center" href="https://www.backtsy.com/"><button type="button" class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
            <Col>
            <div class="card" >
              <h1 class="center">Seller Tools</h1>
              <p>Efficient tools for professional online sellers. Helps you automate repetive tasks.</p>
              <a class="center" href="https://seller-tools.com/"><button type="button" class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
        <Row>
            
            <Col>
              <div class="card" >
                <h1 class="center">Sales Samurai</h1>
                <p class="p-card">Etsy SEO tool. Data anlytics that gives you insights to skyrocket your Etsy sales.</p>
                <a class="center" href="https://salesamurai.io/"><button class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
            
            <Col>
            <div class="card" >
              <h1 class="center">Inventora</h1>
              <p>The inventory system for every handmade business.</p>
              <a class="center" href="https://inventora.co/"><button type="button" class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
            <Col>
            <div class="card" >
              <h1 class="center">CraftMaker Pro</h1>
              <p>Pricing automation tool to get you the most sales.</p>
              <a class="center" href="https://www.craftmakerpro.com/"><button type="button" class="btn btn-primary">Learn More</button></a>
              </div>
            </Col>
          </Row>
        </Container>
        
        
        <ul class="footer">
        <li class="footer-li"><h1><span class="text-gradient-footer">🚀 Getsy Apps</span></h1></li>
      </ul>
      </header>
    </div>
  );
}

export default App;
