import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import {Route, Routes, BrowserRouter} from "react-router-dom";
import App from "./App"
import Find from "./Pages/Find"

const routes = () => {
    return(
        <BrowserRouter>
            <Routes>
                
                <Route path="/find-us-online" element={<Find/>}/>
                <Route path="/" element={<App/>}/>

            </Routes>

        </BrowserRouter>

    )

};

export default routes;